import { Route, Routes } from "react-router-dom";
import AdminTemplatesAddForm from "../../Components/Forms/Administration/AdminTemplatesAddForm/AdminTemplatesAddForm";
import AdminAgreements from "../../Pages/Administration/AdminAgreements";
import AdminAssessmentRefDetail from "../../Pages/Administration/AdminAsessmentRefDetail/AdminAsessmentRefDetail";
import AdminAttackRef from "../../Pages/Administration/AdminAttackRef/AdminAttackRef";
import AdminC2Systems from "../../Pages/Administration/AdminC2Systems";
import AdminCampaignsList from "../../Pages/Administration/AdminCampaignsList/AdminCampaignsList";
import AdminCustomers from "../../Pages/Administration/AdminCustomers";
import AdminEditProject from "../../Pages/Administration/AdminEditProject";
import AdminEndpointAssessment from "../../Pages/Administration/AdminEndpointAssessment";
import AdminFocus from "../../Pages/Administration/AdminFocus/AdminFocus";
import AdminNewRelease from "../../Pages/Administration/AdminNewRelease/AdminNewRelease";
import AdminRecommendations from "../../Pages/Administration/AdminRecommendations/Recommendations";
import AdminReportingTool from "../../Pages/Administration/AdminReportingTool";
import AdminReprocessResults from "../../Pages/Administration/AdminReprocessResults";
import AdminServerSettings from "../../Pages/Administration/AdminServerSettings/AdminServerSettings";
import AdminServiceProvider from "../../Pages/Administration/AdminServiceProvider/AdminServiceProvider";
import AdminSettings from "../../Pages/Administration/AdminSettings";
import AdminSimulations from "../../Pages/Administration/AdminSimulations";
import AdminTemplates from "../../Pages/Administration/AdminTemplates";
import AdminUserInvites from "../../Pages/Administration/AdminUserInvites";
import AdminUsers from "../../Pages/Administration/AdminUsers";
import AdminUtilityFiles from "../../Pages/Administration/AdminUtilityFiles";
import SSOConfigurations from "../../Pages/Administration/SSOConfigurations/SSOConfigurations";
import AVExpectedAlerts from "../../Pages/AlertValidation/AVExpectedAlerts/AVExpectedAlerts";
import UnifiedBuilder from "../../Pages/UnifiedBuilder/UnifiedBuilder";

const AdministrationRoutes = () => {
  return (
    <Routes>
      <Route path="admin" element={<AdminSettings />} />
      <Route path="customers" element={<AdminCustomers />} />
      <Route path="reporttool" element={<AdminReportingTool />} />
      <Route path="templates" element={<AdminTemplates />} />
      <Route path="editProject/:projectUUID" element={<AdminEditProject />} />
      <Route path="reprocess-results" element={<AdminReprocessResults />} />
      <Route path="users" element={<AdminUsers />} />
      <Route path="list-invites" element={<AdminUserInvites />} />
      <Route path="c2systems" element={<AdminC2Systems />} />
      <Route path="assess-references" element={<AdminEndpointAssessment />} />
      <Route
        path="assess-references/:refUUID"
        element={<AdminAssessmentRefDetail />}
      />
      <Route path="attack-references" element={<AdminAttackRef />} />
      <Route
        path="agreements"
        title="Agreements"
        element={<AdminAgreements />}
      />
      <Route path="server" element={<AdminServerSettings />} />
      <Route path="new-release" element={<AdminNewRelease />} />
      <Route path="focus" element={<AdminFocus />} />
      <Route path="simulations" element={<AdminSimulations />} />
      <Route path="utilities" element={<AdminUtilityFiles />} />
      <Route path="recommendations" element={<AdminRecommendations />} />
      <Route path="templates/add" element={<AdminTemplatesAddForm />} />
      <Route path="campaigns/list" element={<AdminCampaignsList />} />
      <Route path="serviceprovider" element={<AdminServiceProvider />} />
      <Route path="expected-alerts" element={<AVExpectedAlerts />} />
      <Route exact path="server-settings" element={<UnifiedBuilder />} />
      <Route exact path="sso" element={<SSOConfigurations />} />
    </Routes>
  );
};

export default AdministrationRoutes;
