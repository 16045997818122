import { List, ListItem } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { DataGridWithStyles } from '../../../Components';
import { DataContext } from '../../../Context/dataContext';
import { getRequest } from '../../../Helpers/httpRequests';

const SSOConfigurations = () => {
  const { accessToken } = useContext(DataContext);
  const [connections, setConnections] = useState([])
  const [dataTableRows, setDataTableRows] = useState([])
  const [dataTableColumns, setDataTableColumns] = useState([])
  // useEffect(() => {
  //
  //   const getConnections = async () => {
  //     const res = await getRequest("/react/api/admin/sso", accessToken);
  //     console.log(res.data)
  //     setConnections(res.data)
  //   };
  //   getConnections()
  //
  //
  // }, [])

  useEffect(() => {
    const populateDataGrid = (connections) => {
      const columns = [
        {
          field: "name",
          headerName: "Name",
          maxWidth: 250,
          flex: 1,
        },
        {
          field: "display_name",
          headerName: "Display Name",
          maxWidth: 250,
          flex: 1,
        },
      ];

      const rows = connections.map((conn) => {
        return {
          id: conn.id,
          name: conn.name,
          display_name: conn.display_name
        };
      });

      setDataTableRows(rows);
      setDataTableColumns(columns);
    };
    const getAlerts = async () => {
      const res = await getRequest("/react/api/admin/sso", accessToken);
      // strategy waad is windows active directory or something similar
      console.log(res.data)
      populateDataGrid(res.data);
    };

    getAlerts();
  }, [accessToken]);

  return (
    <>
      <div>SSOConfigurations </div>
      <List>
        {connections.length > 0 && connections.map((conn) => {
          return (
            <ListItem key={conn.id}>{conn.name}</ListItem>
          )
        })}
      </List>
      <DataGridWithStyles
        name="admin-customers"
        autoHeight
        pointer
        paper
        rows={dataTableRows}
        columns={dataTableColumns}
      />
    </>

  )
}


export default SSOConfigurations 
