import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  FaBook,
  FaBuilding,
  FaCog,
  FaExclamationTriangle,
  FaEye,
  FaFile,
  FaFileCode,
  FaFileContract,
  FaMicrochip,
  FaPlus,
  FaServer,
  FaSlidersH,
  FaTachometerAlt,
  FaTerminal,
  FaUserCog,
  FaUserFriends,
  FaUserPlus,
  FaUsers
} from "react-icons/fa";
import { LinkWithCustomerUUID } from "../../../Components/Links";

const iconColor = "#BDCCD4";
const iconSize = "16px";

const ServiceProvider = ({ open }) => {
  const data = {
    navHeader: "ADMINISTRATION",
    links: [
      {
        icon: <FaUserCog color={iconColor} size={iconSize} />,
        text: "Administration Settings",
        to: "/administration/admin",
      },
      {
        icon: <FaUserFriends color={iconColor} size={iconSize} />,
        text: "Customers",
        to: "/administration/customers",
      },
      {
        icon: <FaBuilding color={iconColor} size={iconSize} />,
        text: "Service Providers",
        to: "/administration/serviceprovider",
      },
      {
        icon: <FaSlidersH color={iconColor} size={iconSize} />,
        text: "Reporting Tool",
        to: "/administration/reporttool",
      },
      {
        icon: <FaFile color={iconColor} size={iconSize} />,
        text: "Templates",
        to: "/administration/templates",
      },
      {
        icon: <FaTerminal color={iconColor} size={iconSize} />,
        text: "Reprocess Results",
        to: "/administration/reprocess-results",
      },
      {
        icon: <FaUsers color={iconColor} size={iconSize} />,
        text: "Users",
        to: "/administration/users",
      },
      {
        icon: <FaUserPlus color={iconColor} size={iconSize} />,
        text: "User Invites",
        to: "/administration/list-invites",
      },
      {
        icon: <FaPlus color={iconColor} size={iconSize} />,
        text: "Simulations",
        to: "/administration/simulations",
      },
      {
        icon: <FaTachometerAlt color={iconColor} size={iconSize} />,
        text: "Campaigns",
        to: "administration/campaigns/list",
      },
      {
        icon: <FaBook color={iconColor} size={iconSize} />,
        text: "Recommendations",
        to: "/administration/recommendations",
      },
      {
        icon: <FaMicrochip color={iconColor} size={iconSize} />,
        text: "C2 Systems",
        to: "/administration/c2systems",
      },
      {
        icon: <FaBook color={iconColor} size={iconSize} />,
        text: "Endpoint Assessment",
        to: "/administration/assess-references",
      },
      {
        icon: <FaBook color={iconColor} size={iconSize} />,
        text: "ATT&CK® Library",
        to: "/administration/attack-references",
      },
      {
        icon: <FaFileContract color={iconColor} size={iconSize} />,
        text: "Agreements",
        to: "/administration/agreements",
      },
      {
        icon: <FaServer color={iconColor} size={iconSize} />,
        text: "Server Settings",
        to: "/administration/server",
      },
      {
        icon: <FaFileCode color={iconColor} size={iconSize} />,
        text: "Utility Files",
        to: "/administration/utilities",
      },
      {
        icon: <FaFileCode color={iconColor} size={iconSize} />,
        text: "New Release",
        to: "/administration/new-release",
      },
      {
        icon: <FaEye color={iconColor} size={iconSize} />,
        text: "Focus",
        to: "/administration/focus",
      },
      {
        icon: <FaExclamationTriangle color={iconColor} size={iconSize} />,
        text: "Expected Alerts",
        to: "/administration/expected-alerts",
      },
      {
        icon: <FaCog color={iconColor} size={iconSize} />,
        text: "UnifiedBuilder Settings",
        to: "/administration/server-settings",
      },
      {
        icon: <FaCog color={iconColor} size={iconSize} />,
        text: "SSO Configurations",
        to: "/administration/sso",
      },
    ],
  };

  return (
    <div>
      {open ? (
        <Typography
          variant="h6"
          px={1}
          mt={3}
          sx={{ fontSize: 10, color: "#BDCCD4" }}
        >
          {data.navHeader}
        </Typography>
      ) : null}
      {data.links.map((link, index) => {
        return (
          <ListItem key={link.text} disablePadding sx={{ display: "block" }}>
            <LinkWithCustomerUUID
              to={link.to}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <ListItemButton
                sx={{
                  minHeight: 32,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {link.icon}
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: "12px" }}
                  primary={link.text}
                  sx={{ opacity: open ? 1 : 0, color: "white" }}
                />
              </ListItemButton>
            </LinkWithCustomerUUID>
          </ListItem>
        );
      })}
    </div>
  );
};

export default ServiceProvider;
