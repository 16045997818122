import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardHeader,
  Chip,
  Grid,
  IconButton,
  Modal,
  Stack,
  Tooltip,
  Typography,
  Button,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import {
  FaArchive,
  FaPencilAlt,
  FaPlug,
  FaTrashAlt,
  FaRobot,
} from "react-icons/fa";
import { FormModal } from "../../../Components";
import CrowdstrikeConfigForm from "../../../Components/Forms/AlertValidation/CrowdstrikeConfigForm";
import MicrosoftConfigForm from "../../../Components/Forms/AlertValidation/MicrosoftConfigForm";
import SentinelConfigForm from "../../../Components/Forms/AlertValidation/SentinelConfigForm";
import SentinelOneConfigForm from "../../../Components/Forms/AlertValidation/SentinelOneConfigForm";
import SecurityToolEditForm from "../../../Components/Forms/Config/SecurityToolEditForm";
import { DataContext } from "../../../Context/dataContext";
import { getRequest, postRequest } from "../../../Helpers/httpRequests";
import { useAppSelector } from "../../../Redux/app/hooks";
import { COLORS } from "../../../Styles/colors";
import UsageModal from "./UsageModal";
import { toast } from "react-toastify";

const SecurityToolCard = ({ tool, setReload, hide }) => {
  const { accessToken } = useContext(DataContext);
  const darkmode = useAppSelector((state) => state.darkmode);
  const current_customer = useAppSelector((state) => state.customer);
  const [connectedStatus, setConnectedStatus] = useState({});
  const [toolData, setToolData] = useState(null);
  const [openMicrosoft, setOpenMicrosoft] = useState(false);
  const [openCrowdstrike, setOpenCrowdstrike] = useState(false);
  const [openSentinel, setOpenSentinel] = useState(false);
  const [openSentinelOne, setOpenSentinelOne] = useState(false);
  const [editTool, SetEditTool] = useState(false);

  const [archiveModal, setArchiveModal] = useState(false);
  const [modalText, setModalText] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  const vendorImages = {
    Blackberry: require("../../../Images/security_tools_icons/Blackberry.png"),
    Crowdstrike: require("../../../Images/security_tools_icons/crowdstrike.png"),
    Microsoft: require("../../../Images/security_tools_icons/microsoft.jpg"),
    SentinelOne: require("../../../Images/security_tools_icons/sentinel_one.png"),
    sentinel: require("../../../Images/security_tools_icons/sentinel.png"),
    default: require("../../../Images/security_tools_icons/default.png"),
  };

  const closeEditModal = () => {
    SetEditTool(false);
  };

  const deleteConfig = async () => {
    let loadingToast = toast.loading("Attempting to remove tool config");
    const res = await postRequest(
      `/react/api/${current_customer.uuid}/alertvalidation/config/delete_tool/${tool.uuid}`,
      accessToken
    );
    if (res.status === 204) {
      toast.dismiss(loadingToast);
      toast.success("Config removed sucessfully");
      setReload((current) => !current);
      setDeleteModal(false);
    } else {
      toast.dismiss(loadingToast);
      toast.error("Failed to remove config");
    }
  };

  useEffect(() => {
    const fetchConnectionStatus = async () => {
      let endpoint;
      switch (tool.product) {
        case "Defender":
          endpoint = "testmicrosoft";
          break;
        case "Crowdstrike":
          endpoint = "testcrowdstrike";
          break;
        case "Sentinel":
          endpoint = "testsentinel";
          break;
        case "SentinelOne":
          endpoint = "testsentinelOne";
          break;
        default:
          endpoint = "";
      }

      if (endpoint) {
        try {
          const res = await getRequest(
            `/react/api/${current_customer.uuid}/alertvalidation/${endpoint}/${tool.tool_config.uuid}`,
            accessToken
          );
          setConnectedStatus((prevStatus) => ({
            ...prevStatus,
            [tool.uuid]: res?.data?.status || "failed",
          }));
        } catch (error) {
          setConnectedStatus((prevStatus) => ({
            ...prevStatus,
            [tool.uuid]: "failed",
          }));
        }
      }
    };

    if (tool?.tool_config) {
      fetchConnectionStatus();
    }
  }, [tool, current_customer, accessToken]);

  const capitalizeWords = (inputString) => {
    return inputString
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleOpenIntegration = (tool) => {
    setToolData({
      action: "edit",
      tool,
      uuid: tool.uuid,
    });

    // Open specific integration modal based on the tool product
    switch (tool.product) {
      case "Defender":
        setOpenMicrosoft(true);
        break;
      case "Crowdstrike":
        setOpenCrowdstrike(true);
        break;
      case "Sentinel":
        setOpenSentinel(true);
        break;
      case "SentinelOne":
        setOpenSentinelOne(true);
        break;
      default:
        break;
    }
  };

  const handleAddIntegration = (tool) => {
    setToolData({
      action: "add",
      tool,
      uuid: tool.uuid,
    });

    // Open specific modal to add integration
    switch (tool.product) {
      case "Defender":
        setOpenMicrosoft(true);
        break;
      case "Crowdstrike":
        setOpenCrowdstrike(true);
        break;
      case "Sentinel":
        setOpenSentinel(true);
        break;
      case "SentinelOne":
        setOpenSentinelOne(true);
        break;
      default:
        break;
    }
  };

  const handleAutoIntegration = (tool) => {
    setToolData({
      action: "automation",
      tool,
      uuid: tool.uuid,
    });

    // Open specific modal to add integration
    switch (tool.product) {
      case "Defender":
        setOpenMicrosoft(true);
        break;
      case "Crowdstrike":
        setOpenCrowdstrike(true);
        break;
      case "Sentinel":
        setOpenSentinel(true);
        break;
      case "SentinelOne":
        setOpenSentinelOne(true);
        break;
      default:
        break;
    }
  };

  // Function to check if the product should have an integration (returns true if it's a supported integration)
  const hasIntegration = (product) => {
    return (
      product === "Defender" ||
      product === "Crowdstrike" ||
      product === "Sentinel" ||
      product === "SentinelOne"
    );
  };

  const openArchiveModal = () => {
    setModalText(`Are you sure you want to archive ${tool.name}?`);
    setArchiveModal(true); // Open the usage modal for archiving
  };

  return (
    <>
      <Grid item sx={{ display: "flex" }} xs={24} sm={24} md={12} lg={8} xl={6}>
        <Card
          sx={{
            display: "flex",
            width: "100%",
            height: "100%",
            flexDirection: "column",
          }}
        >
          <CardHeader
            sx={{
              background: darkmode
                ? "linear-gradient(to right, rgb(75, 85, 99), rgb(17, 24, 39))"
                : "linear-gradient(to right, #e0eafc, #cfdef3)",
            }}
            avatar={
              <Avatar
                src={vendorImages[tool.vendor] || vendorImages.default}
                sx={{ backgroundColor: "transparent" }}
                variant="square"
              />
            }
            title={
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ marginBottom: -1 }}
              >
                <Typography variant="h6">{tool.name}</Typography>
                {!hide && (
                  <IconButton
                    title="Archive Security Tool"
                    onClick={openArchiveModal}
                  >
                    <FaArchive color={COLORS.error.main} />
                  </IconButton>
                )}
              </Stack>
            }
            subheader={
              <Stack direction="row" justifyContent="space-between">
                <Box>
                  {tool.vendor && capitalizeWords(tool.vendor)}
                  <br />
                  {tool.log_destination &&
                    capitalizeWords(tool.log_destination)}
                </Box>
                <Box>
                  {tool?.tool_config && (
                    <Chip
                      sx={{ marginTop: 1 }}
                      color="primary"
                      variant="outlined"
                      label={`${tool.name} Integration`}
                    />
                  )}
                </Box>
              </Stack>
            }
          />
          <CardActions sx={{ marginTop: "auto" }}>
            <Stack
              display="flex"
              direction="row"
              sx={{ width: "100%" }}
              justifyContent="space-between"
              spacing={1}
            >
              {!hide && (
                <Tooltip title="Edit Security Tool">
                  <IconButton onClick={() => SetEditTool(true)}>
                    <FaPencilAlt />
                  </IconButton>
                </Tooltip>
              )}

              {hasIntegration(tool.product) ? (
                tool?.tool_config ? (
                  <>
                    <Tooltip
                      title={`Open ${tool.name} Integration - ${connectedStatus[tool.uuid] === "success"
                        ? "Connected"
                        : "Not Connected"
                        }`}
                    >
                      <IconButton onClick={() => handleOpenIntegration(tool)}>
                        <FaPlug
                          color={
                            connectedStatus[tool.uuid] === "success"
                              ? COLORS.success.main
                              : COLORS.error.main
                          }
                        />
                      </IconButton>
                    </Tooltip>
                    {hide && (
                      <Box>
                        <>
                          {tool.product === "Defender" && (
                            <Tooltip title="Automated Ticket Closing">
                              <IconButton
                                onClick={() => handleAutoIntegration(tool)}
                              >
                                <FaRobot />
                              </IconButton>
                            </Tooltip>
                          )}

                          <Tooltip title="Delete Config">
                            <IconButton
                              onClick={() => {
                                setDeleteModal(true);
                              }}
                              title="Delete Defender Config"
                            >
                              <FaTrashAlt color={COLORS.error.main} />
                            </IconButton>
                          </Tooltip>
                        </>
                      </Box>
                    )}
                  </>
                ) : (
                  <Tooltip title="Add Security Tool Integration - No Config Made">
                    <IconButton onClick={() => handleAddIntegration(tool)}>
                      <FaPlug />
                    </IconButton>
                  </Tooltip>
                )
              ) : (
                // If the tool does not have an integration, leave this area blank
                <Box sx={{ width: 40 }} />
              )}
            </Stack>
          </CardActions>
        </Card>
      </Grid>

      {/* Microsoft Integration Modal */}
      <Modal
        open={openMicrosoft}
        onClose={() => setOpenMicrosoft(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{ ...modalStyle }}>
          <MicrosoftConfigForm
            toolData={toolData}
            setModalOpen={setOpenMicrosoft}
            onFinish={() => {
              setOpenMicrosoft(false);
              setReload((current) => !current);
            }}
          />
        </Box>
      </Modal>

      {/* Crowdstrike Integration Modal */}
      <Modal
        open={openCrowdstrike}
        onClose={() => setOpenCrowdstrike(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{ ...modalStyle }}>
          <CrowdstrikeConfigForm
            toolData={toolData}
            setModalOpen={setOpenCrowdstrike}
            onFinish={() => {
              setOpenCrowdstrike(false);
              setReload((current) => !current);
            }}
          />
        </Box>
      </Modal>

      {/* Sentinel Integration Modal */}
      <Modal
        open={openSentinel}
        onClose={() => setOpenSentinel(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{ ...modalStyle }}>
          <SentinelConfigForm
            toolData={toolData}
            setModalOpen={setOpenSentinel}
            onFinish={() => {
              setOpenSentinel(false);
              setReload((current) => !current);
            }}
          />
        </Box>
      </Modal>

      {/* SentinelOne Integration Modal */}
      <Modal
        open={openSentinelOne}
        onClose={() => setOpenSentinelOne(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{ ...modalStyle }}>
          <SentinelOneConfigForm
            toolData={toolData}
            setModalOpen={setOpenSentinelOne}
            onFinish={() => {
              setOpenSentinelOne(false);
              setReload((current) => !current);
            }}
            current_customer={current_customer}
          />
        </Box>
      </Modal>

      {/* Edit Tool Modal */}
      <FormModal
        sx={{ display: "flex", flexDirection: "column", width: 500 }}
        open={editTool}
        setOpen={closeEditModal}
      >
        <SecurityToolEditForm
          toolUUID={tool.uuid}
          closeModal={closeEditModal}
          setReload={setReload}
        />
      </FormModal>

      {/* Usage Modal for Archiving */}
      <UsageModal
        open={archiveModal}
        setOpen={setArchiveModal}
        tool={tool}
        setReload={setReload}
        text={modalText}
        instance="archive"
      />

      {/* Delete Modal */}
      <FormModal open={deleteModal} setOpen={setDeleteModal}>
        <Box component="form">
          <Typography
            variant="h6"
            style={{ textAlign: "center", marginBottom: 4 }}
          >
            Are you sure you want to remove {tool.product} Config ?
          </Typography>
          <Stack direction="column" spacing={2}>
            <Button onClick={() => deleteConfig()} variant="contained">
              Yes
            </Button>
          </Stack>
        </Box>
      </FormModal>
    </>
  );
};

const modalStyle = {
  position: "absolute",
  bgcolor: "background.paper",
  top: "40%",
  left: "45%",
  width: "70%",
  height: "55%",
  maxWidth: 900,
  transform: "translate(-25%, -50%)",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

export default SecurityToolCard;
